import personalData from './components/personal-data/index.vue'
import contactData from './components/contact-data/index.vue'
import addressData from './components/address-data/index.vue'
import syncSocial from './components/sync-social/index.vue'
import changePassword from './components/change-password/index.vue'
import settings from './components/settings/index.vue'
import {mapActions, mapGetters} from "vuex";
export default {
  name: "profile-user-info",
  components:{
    personalData,
    contactData,
    addressData,
    syncSocial,
    changePassword,
    settings
  },
  created() {
    if(this.user)
    this.fetchUser()
  },
  computed:{
    ...mapGetters({
      user:`profile/user`,
      loadingUser:`profile/loadingUser`
    })
  },
  methods:{
    ...mapActions({
      fetchUser: `profile/FETCH_USER_DATA`,
    }),
  },
}