import {mapActions, mapGetters} from "vuex";
import {email, maxLength, minLength, required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
export default {
  name: "contact-data",
  mixin:[validationMixin],
  data(){
    return{
      payload:{
        phone:'',
        email:'',
      },
      validationErrors: {},
      isEdit:false
    }
  },
  validations() {
    return {
      payload: {
        email: {
          maxLength: maxLength(30),
          email
        },
        phone: {
          required,
          maxLength: maxLength(15)
        },
      },
    }
  },
  computed:{
    ...mapGetters({
      user:`profile/user`,
      loadingUser:`profile/loadingUser`
    }),
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 30));
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    phoneErrors() {
      let error = [];
      if (!this.$v.payload.phone.$dirty) {
        return error;
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.phone.maxLength) {
        error.push(this.$t('validationLengthPhone').replace(':count', 15));
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },
  created() {
    // this.fetchUser().then(()=>{
      this.setData()
    // })
  },
  methods:{
    ...mapActions({
      fetchUser: `profile/FETCH_USER_DATA`,
      editUserContact:`profile/EDIT_USER_CONTACT`
    }),
    setData(){
      this.payload.phone = this.user.phone;
      this.payload.email = this.user.email;
    },
    cancel(){
      this.isEdit = false
      this.setData()
    },
    submit(){
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.editUserContact(this.payload).then(()=>{
          this.isEdit = false;
          this.$toasted.success(this.$t('successChangeData'));
          this.fetchUser().then(()=>{
            this.setData()
          })
        }).catch(error => {
          if (error.response.status === 422) {
            this.$toasted.error(this.$t('failChangeData'));
          }
        });
      }
    }
  }
}