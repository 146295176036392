import {mapMutations, mapGetters, mapActions} from 'vuex';
import {maxLength, minLength,required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import Vue from 'vue'
export default {
  name: "personal-data",
  mixin: [validationMixin],
  data() {
    return {
      payload: {
        name:'',
        surname:'',
        lastName:'',
      },
      validationErrors: {},
      isEdit:false,
    }
  },
  validations() {
    return {
      payload: {
        name: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(20),
        },
        surname: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(20),
        },
        lastName: {
          minLength: minLength(3),
          maxLength: maxLength(20),
        },
      },
    }
  },
  computed:{
    ...mapGetters({
      user:`profile/user`,
      isAuthenticated: `auth/isAuthenticated`,
      loadingUser:`profile/loadingUser`
    }),
    nameErrors() {
      let error = [];
      if (!this.$v.payload.name.$dirty) {
        return error;
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 20));
      }
      if (!this.$v.payload.name.minLength) {
        error.push(this.$t('validationMin').replace(':count', 3));
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    secondNameErrors() {
      let error = [];
      if (!this.$v.payload.surname.$dirty) {
        return error;
      }
      if (!this.$v.payload.surname.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.surname.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 20));
      }
      if (!this.$v.payload.surname.minLength) {
        error.push(this.$t('validationMin').replace(':count', 3));
      }
      if (this.validationErrors.surname) {
        this.validationErrors.surname.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    lastNameErrors() {
      let error = [];
      if (!this.$v.payload.lastName.$dirty) {
        return error;
      }

      if (!this.$v.payload.lastName.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 20));
      }
      if (!this.$v.payload.lastName.minLength) {
        error.push(this.$t('validationMin').replace(':count', 3));
      }
      if (this.validationErrors.lastName) {
        this.validationErrors.lastName.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },
  watch:{
    // user(val){
    //   if(val){
    //     this.setData()
    //   }
    // }
  },
  created() {
    // this.fetchUser().then(()=>{
      this.setData()
    // })
  },
  methods:{
    ...mapActions({
      fetchUser: `profile/FETCH_USER_DATA`,
      editUserData:`profile/EDIT_USER_INFO`
    }),
    setData(){
      this.payload.name = this.user.first_name;
      this.payload.surname = this.user.last_name;
      this.payload.lastName = this.user.middle_name;
    },
    cancel(){
      this.isEdit = false
      this.setData()
    },
    submit(){
      let obj = {}
      obj.first_name = this.payload.name
      obj.last_name = this.payload.surname
      obj.middle_name = this.payload.lastName
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.editUserData(obj).then(()=>{
          this.isEdit = false;
          this.$toasted.success(this.$t('successChangeData'));
          this.fetchUser().then(()=>{
            this.setData()
          })
        }).catch(error => {
          if (error.response.status === 422) {
            this.$toasted.error(this.$t('failChangeData'));
          }
        });
      }
    }
  }
}