import {mapActions} from "vuex";

export default {
  name: "another-address",
  props: ['address'],
  methods: {
    ...mapActions({
      setMainAddress:`profile/CHANGE_MAIN_USER_ADDRESS`,
      fetchUser: `profile/FETCH_USER_DATA`,
      removeUserAddress:`profile/REMOVE_USER_ADDRESS`
    }),
    setMain(id){
      let obj = {}
      obj.id = id
      obj.is_main = {is_main:true}
      this.setMainAddress(obj).then(()=>{
        this.$toasted.success(this.$t('youAddressRefresh'));
        this.fetchUser()
      }).catch(error => {
        if (error.response.status === 422) {
          console.log(error.response.status)
        }
      });
    },
    removeAddress(address){
      let obj = {}
      obj.id = address.id
      // this.$emit('deleteAddress',obj)
      // // console.log(obj);
      this.removeUserAddress(obj).then(()=>{
        this.$toasted.success(this.$t('youAddressRemove'));
        this.fetchUser()
      }).catch(error => {
        if (error.response.status === 422) {
          console.log(error.response)
        }
      });
    },
    editAddress(){
      console.log('123')
      this.$emit('editAddressForm',this.address)
    }
  },
}