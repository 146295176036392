import anotherAddress from './address-list/index.vue'
import {mapActions, mapGetters, mapMutations} from "vuex";
import {maxLength, minLength, required, email, helpers} from "vuelidate/lib/validators";
const AZazValidator = helpers.regex('AZaz', /^[a-z\d .,-_]*$/i);
import {validationMixin} from "vuelidate";
import mainSelect from '@/components/main-select/index.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import Vue from "vue";
Vue.component('v-select', vSelect)
export default {
  mixin: [validationMixin],
  name: "address-data",
  components: {
    anotherAddress,
    mainSelect
  },
  data() {
    return {
      secondAddresses: [],
      showMainRadio:false,
      payload: {
        id: '',
        country: {},
        region: {},
        district: '',
        city: '',
        street: '',
        house: '',
        flat: '',
        //
        index: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        is_main: false,
        inn: ''
      },
      activeEditAddress: false,
      activeMain: false,
      validationErrors: {},
    }
  },
  validations() {
    if (this.payload.country.title === 'Russian Federation') {
      return {
        payload: {
          country: {
            required,
          },
          region: {
            required,
          },
          district: {
            AZazValidator,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          city: {
            AZazValidator,
            required,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          street: {
            AZazValidator,
            required,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          index: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          first_name: {
            AZazValidator,
            required,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          last_name: {
            AZazValidator,
            required,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          phone: {
            required,
            maxLength: maxLength(15),
          },
          inn: {
            required,
            // maxLength: maxLength(12),
            // minLength: minLength(12),
          },
          email: {
            email,
            required,
            minLength: minLength(3),
            maxLength: maxLength(99),
          },
          house:{
            required
          }
        },
      }
    } else {
      return {
        payload: {
          country: {
            required,
          },
          region: {
            required,
          },
          district: {
            AZazValidator,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          city: {
            AZazValidator,
            required,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          street: {
            AZazValidator,
            required,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          index: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          first_name: {
            AZazValidator,
            required,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          last_name: {
            AZazValidator,
            required,
            minLength: minLength(1),
            maxLength: maxLength(20),
          },
          phone: {
            required,
            maxLength: maxLength(15),
          },
          email: {
            email,
            required,
            minLength: minLength(3),
            maxLength: maxLength(99),
          },
          house:{
            required
          }
        },
      }
    }
  },
  created() {
    // this.fetchUser().then(()=>{
    this.fetchCountriesList()
    for (let i in this.user.addresses.data) {
      if (this.user.addresses.data[i].is_main === false) {
        this.secondAddresses.push(this.user.addresses.data[i]);
      }
    }
    // })
  },
  watch: {
    'user': function (val) {
      if (val) {
        this.secondAddresses = []
        for (let i in this.user.addresses.data) {
          if (this.user.addresses.data[i].is_main === false) {
            this.secondAddresses.push(this.user.addresses.data[i]);
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      user: `profile/user`,
      loadingUser: `profile/loadingUser`,

      countriesList: 'profile/countriesList',
      regionsList: 'profile/regionsList',
    }),
    houseErrors() {
      let error = [];
      if (!this.$v.payload.house.$dirty) {
        return error;
      }
      if (!this.$v.payload.house.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.house) {
        this.validationErrors.house.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    innErrors() {
      let error = [];
      if (!this.$v.payload.inn.$dirty) {
        return error;
      }
      if (!this.$v.payload.inn.required) {
        error.push(this.$t('validationRequired'))
      }
      // if (!this.$v.payload.inn.maxLength) {
      //   error.push(this.$t('validationMax').replace(':count', 12));
      // }
      // if (!this.$v.payload.inn.minLength) {
      //   error.push(this.$t('validationMin').replace(':count', 12));
      // }
      if (this.validationErrors.inn) {
        this.validationErrors.inn.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    nameErrors() {
      let error = [];
      if (!this.$v.payload.first_name.$dirty) {
        return error;
      }
      if (!this.$v.payload.first_name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.first_name.AZazValidator) {
        error.push(this.$t('validationEnglish'))
      }
      if (!this.$v.payload.first_name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 20));
      }
      if (!this.$v.payload.first_name.minLength) {
        error.push(this.$t('validationMin').replace(':count', 1));
      }
      if (this.validationErrors.first_name) {
        this.validationErrors.first_name.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    secondNameErrors() {
      let error = [];
      if (!this.$v.payload.last_name.$dirty) {
        return error;
      }
      if (!this.$v.payload.last_name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.last_name.AZazValidator) {
        error.push(this.$t('validationEnglish'))
      }
      if (!this.$v.payload.last_name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 20));
      }
      if (!this.$v.payload.last_name.minLength) {
        error.push(this.$t('validationMin').replace(':count', 1));
      }
      if (this.validationErrors.last_name) {
        this.validationErrors.last_name.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    streetErrors() {
      let error = [];
      if (!this.$v.payload.street.$dirty) {
        return error;
      }
      if (!this.$v.payload.street.minLength) {
        error.push(this.$t('validationMin').replace(':count', 1));
      }
      if (!this.$v.payload.street.AZazValidator) {
        error.push(this.$t('validationEnglish'))
      }
      if (!this.$v.payload.street.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 20));
      }
      if (!this.$v.payload.street.required) {
        error.push(this.$t('validationRequired'));
      }
      if (this.validationErrors.street) {
        this.validationErrors.street.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    countryErrors() {
      let error = [];
      if (!this.$v.payload.country.$dirty) {
        return error;
      }
      if (!this.$v.payload.country.required) {
        error.push(this.$t('validationRequired'));
      }
      if (this.validationErrors.country) {
        this.validationErrors.country.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    regionErrors() {
      let error = [];
      if (!this.$v.payload.region.$dirty) {
        return error;
      }
      if (!this.$v.payload.region.required) {
        error.push(this.$t('validationRequired'));
      }
      if (this.validationErrors.region) {
        this.validationErrors.region.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    districtErrors() {
      let error = [];
      if (!this.$v.payload.district.$dirty) {
        return error;
      }
      if (!this.$v.payload.district.minLength) {
        error.push(this.$t('validationMin').replace(':count', 1));
      }
      if (!this.$v.payload.district.AZazValidator) {
        error.push(this.$t('validationEnglish'))
      }
      if (!this.$v.payload.district.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 20));
      }
      if (this.validationErrors.district) {
        this.validationErrors.district.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    cityErrors() {
      let error = [];
      if (!this.$v.payload.city.$dirty) {
        return error;
      }
      if (!this.$v.payload.city.minLength) {
        error.push(this.$t('validationMin').replace(':count', 1));
      }
      if (!this.$v.payload.city.AZazValidator) {
        error.push(this.$t('validationEnglish'))
      }
      if (!this.$v.payload.city.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 20));
      }
      if (!this.$v.payload.city.required) {
        error.push(this.$t('validationRequired'));
      }
      if (this.validationErrors.city) {
        this.validationErrors.city.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    indexErrors() {
      let error = [];
      if (!this.$v.payload.index.$dirty) {
        return error;
      }
      if (!this.$v.payload.index.minLength) {
        error.push(this.$t('validationMin').replace(':count', 1));
      }
      if (!this.$v.payload.index.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 20));
      }
      if (!this.$v.payload.index.required) {
        error.push(this.$t('validationRequired'));
      }
      if (this.validationErrors.index) {
        this.validationErrors.index.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 30));
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    phoneErrors() {
      let error = [];
      if (!this.$v.payload.phone.$dirty) {
        return error;
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.phone.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 15));
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },

    getMainAddress() {
      for (let i in this.user.addresses.data) {
        if (this.user.addresses.data[i].is_main === true) {
          return this.user.addresses.data[i];
        }
      }
    },
  },
  methods: {
    ...mapMutations({
      changePopupAddAddress: `profile/CHANGE_POPUP_ADD_ADDRESS`,
    }),
    ...mapActions({
      fetchUser: `profile/FETCH_USER_DATA`,
      removeUserAddress: `profile/REMOVE_USER_ADDRESS`,
      editUserAddress: `profile/EDIT_USER_ADDRESS`,

      fetchCountriesList: `profile/FETCH_COUNTRIES_LIST`,
      fetchRegionsList: `profile/FETCH_REGIONS_LIST`,
    }),
    removeAddress(address) {
      let obj = {}
      obj.id = address.id
      // this.$emit('deleteAddress',obj)
      // // console.log(obj);
      this.removeUserAddress(obj).then(() => {
        this.$toasted.success(this.$t('youAddressRemove'));
        this.fetchUser()
      }).catch(error => {
        if (error.response.status === 422) {
          console.log(error.response)
        }
      });
    },
    changeRegion() {
      this.payload.region.title = ''
      this.fetchRegionsList(this.payload.country.id).then(()=>{
        if(this.regionsList.length === 1){
          this.payload.region = this.regionsList[0]
        }
      })
    },
    edit(value) {
      this.activeEditAddress = true
      this.payload.id = value.id
      this.payload.country.title = value.country
      this.payload.country.id = value.country_id
      this.payload.region.title = value.region
      this.payload.region.id = value.region_id
      this.payload.district = value.district
      this.payload.city = value.city
      this.payload.street = value.street
      this.payload.house = value.house
      this.payload.flat = value.flat
      this.payload.index = value.index
      this.payload.first_name = value.first_name
      this.payload.last_name = value.last_name
      this.payload.phone = value.phone
      this.payload.email = value.email
      if(this.payload.country.title === 'Russian Federation'){
        this.payload.inn = value.inn
      }else {
        this.payload.inn = ''
      }
      if(value.is_main === true){
        this.showMainRadio = false
        this.payload.is_main = value.is_main
      }else{
        this.showMainRadio = true
        this.payload.is_main = value.is_main
      }
      console.log(this.payload);
      this.fetchRegionsList(this.payload.country.id)
    },
    changeAddress() {
      if(this.payload.country.title !== 'Russian Federation')
      this.payload.inn = 111111111111
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let obj = {}
        obj.id = this.payload.id
        obj.country_id = this.payload.country.id
        obj.region_id = this.payload.region.id
        obj.district = this.payload.district
        obj.city = this.payload.city
        obj.street = this.payload.street
        obj.house = this.payload.house
        obj.flat = this.payload.flat
        obj.index = this.payload.index
        obj.first_name = this.payload.first_name
        obj.last_name = this.payload.last_name
        obj.phone = this.payload.phone
        obj.email = this.payload.email
        obj.inn = this.payload.inn
        obj.is_main = this.payload.is_main
        this.disable = true
        // if (this.isMain) {
        //   this.payload.is_main = this.isMain
        //
        // }
        this.editUserAddress(obj).then(() => {
          this.$toasted.success(this.$t('youAddressEdit'));
          this.fetchUser()
          this.activeEditAddress = false
        }).catch(error => {
          if (error.response.status === 422) {
            console.log(error.response)
          }
        });
      }
    }
  }
}