export default {
  name: "settings-profile",
  data() {
    return {
      alerts: {
        inProcess: {
          status: false
        },
        assembled: {
          status: false
        },
        inRoad: {
          status: false
        },
        delivered: {
          status: false
        }
      }
    }
  }
}