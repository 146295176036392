import {mapActions, mapGetters} from "vuex";
import {email, maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: "change-password",
  mixin: [validationMixin],
  data() {
    return {
      payload: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      isEdit: false,
      activeButton: false,
      validationErrors: {},
    }
  },
  validations() {
    return {
      payload: {
        oldPassword: {
          required,
          minLength: minLength(8),
        },
        newPassword: {
          required,
          minLength: minLength(8),
        },
        confirmPassword: {
          required,
          minLength: minLength(8),
          sameAsPassword: sameAs('newPassword')
        },
      },
    }
  },
  computed: {
    oldPasswordErrors() {
      let error = [];
      if (!this.$v.payload.oldPassword.$dirty) {
        return error;
      }
      if (!this.$v.payload.oldPassword.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.oldPassword.minLength) {
        error.push(this.$t('validationMin').replace(':count', 8));
      }
      if (this.validationErrors.oldPassword) {
        this.validationErrors.oldPassword.forEach((row) => {
          error.push(row);
        })
      }
      return error;

    },
    newPasswordErrors() {
      let error = [];
      if (!this.$v.payload.newPassword.$dirty) {
        return error;
      }
      if (!this.$v.payload.newPassword.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.newPassword.minLength) {
        error.push(this.$t('validationMin').replace(':count', 8));
      }
      if (this.validationErrors.newPassword) {
        this.validationErrors.newPassword.forEach((row) => {
          error.push(row);
        })
      }
      return error;

    },
    passwordConfirmationErrors() {
      let error = [];
      if (!this.$v.payload.confirmPassword.$dirty) {
        return error;
      }
      if (!this.$v.payload.confirmPassword.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.confirmPassword.sameAsPassword) {
        error.push(this.$t('validationSameAs'));
      }
      if (this.validationErrors.confirmPassword) {
        this.validationErrors.confirmPassword.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      changePassword: 'profile/UPDATE_USER_PASSWORD'
    }),
    resetForm() {
      this.isEdit = false
      this.payload.oldPassword = ''
      this.payload.newPassword = ''
      this.payload.confirmPassword = ''
      this.validationErrors = {}
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let obj = {}
        obj.password = this.payload.newPassword
        obj.old_password = this.payload.oldPassword
        obj.password_confirmation = this.payload.confirmPassword
        this.changePassword(obj)
          .then(() => {
            this.$toasted.success(this.$t('successChangePassword'));
            this.resetForm()
          }).catch(error => {
          if (error.response.status === 418) {
            this.$toasted.error(this.$t('failChangePassword'));
            this.validationErrors = error.response.data.errors;
          }
        });
      }
    }
  }
}